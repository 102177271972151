interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  svgWidth: number;
  svgHeight: number;
  radialScale: d3.ScaleLinear<number, number, never>;
}

export default function renderLinedCircles({
  svg,
  svgHeight,
  svgWidth,
  radialScale,
}: Props) {
  const ticks = [
    3.4, 3.8, 4.2, 4.6, 5.0, 5.4, 5.8, 6.2, 6.6, 7.0, 7.4, 7.8, 8.2, 8.6, 9.0,
    9.4, 10,
  ];

  svg
    .selectAll(".circle")
    .data(ticks)
    .join((enter) =>
      enter
        .append("circle")
        .attr("cx", svgWidth / 2)
        .attr("cy", svgHeight / 2)
        .attr("fill", "none")
        .attr("stroke", "#333333")
        .attr("r", (d) => radialScale(d))
        .attr("stroke-width", 1.2)
    );
}
