import React from "react";
import { createRoot } from "react-dom/client";
import App from "../intake-form/App";
import { I18nextProvider } from "react-i18next";
import i18n from "../intake-form/i18n";
import { PatientProvider } from "../context/PatientContext";

createRoot(document.getElementById("main")).render(
  <>
    <I18nextProvider i18n={i18n}>
      <PatientProvider>
        <App />
      </PatientProvider>
    </I18nextProvider>
  </>
);
