interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  labelsData: labelData[];
}

export default function renderBottomLabels({ labelsData, svg }: Props) {
  const labelNamesCoords = (i: number): string => {
    return `translate(${((i === 9 ? 24 : 23) / 100) * window.innerWidth}, ${
      ((i + 1) * ((70 / 100) * window.innerWidth)) / 10
    })`;
  };

  const labelNumbersCoords = (i: number): string => {
    return `translate(${(17 / 100) * window.innerWidth}, ${
      ((i + 1) * ((70 / 100) * window.innerWidth)) / 10
    })`;
  };

  const labelCirclesCoords = (i: number): string => {
    return `translate(${(14 / 100) * window.innerWidth}, ${
      ((i + 1) * ((70 / 100) * window.innerWidth)) / 10 - 5
    })`;
  };

  svg
    .selectAll(".circles")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("circle")
        .attr("transform", (d, i) => labelCirclesCoords(i))
        .attr("r", 3)
        .attr("stroke", (d, i) => d.circle_coord.color)
        .attr("fill", (d, i) => d.circle_coord.color)
        .attr("stroke-width", 2)
    );

  svg
    .selectAll(".bottomLabelNumber")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("g")
        .attr("transform", (d, i) => labelNumbersCoords(i))
        .append("text")
        .text((d) => `${d.number}:`)
        .attr("class", "bottom-label")
    );

  svg
    .selectAll(".bottomlabel")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("g")
        .attr("transform", (d, i) => labelNamesCoords(i))
        .append("text")
        .text((d) => d.name)
        .attr("class", "bottom-label")
    );
}
