import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import renderSpiderChart from "./spiderChartComonents/renderSpiderChart";
import { usePatientContext } from "../context/PatientContext";
import domtoimage from "dom-to-image-more";

interface Props {}

export default function RadarChart({}: Props) {
  const [svgWidth] = useState<number>(
    window.innerWidth <= 350 ? 400 : window.innerWidth <= 450 ? 450 : 700
  );
  const [svgHeight] = useState<number>(
    window.innerWidth <= 350 ? 400 : window.innerWidth <= 450 ? 400 : 700
  );
  const [circleColors] = useState([
    "#ff835d",
    "#d92965",
    "#f1c200",
    "#c2a000",
    "#c96800",
    "#f17154",
    "#d92965",
    "#ae2c30",
    "#ff8a00",
    "#e75873",
  ]);
  const { t } = useTranslation();
  const svgRef = useRef();
  const labelsSvgRef = useRef();
  const {
    height,
    weight,
    targetWeight,
    exercise,
    sleep,
    stressLevel,
    carbohydrateIntake,
    work,
    dateOfBirth,
    setSpiderChartImage,
  } = usePatientContext();

  const setChartViewBoxProperties = (): string => {
    const getMinX = (): number => {
      if (window.innerWidth <= 350) return -165;
      else if (window.innerWidth <= 450) return -150;
      else return -100;
    };

    const getMinY = (): number => {
      if (window.innerWidth <= 350) return -0;
      else if (window.innerWidth <= 450) return -0;
      else if (window.innerWidth >= 1000 && window.innerHeight <= 900)
        return -150;
      else return -20;
    };

    const getWidth = (): number => {
      if (window.innerWidth <= 350) return 750;
      else if (window.innerWidth <= 450) return 750;
      else return 1100;
    };

    const getHeight = (): number => {
      if (window.innerWidth <= 450) return 400;
      else return 1100;
    };

    return `${getMinX()} ${getMinY()} ${getWidth()} ${getHeight()}`;
  };

  const setChartImageData = () => {
    var node = document.getElementById("spider-diagram");
    node.style.backgroundColor = "#eaebe7";

    domtoimage
      .toPng(node)
      .then((dataUrl) => {
        setSpiderChartImage(dataUrl);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    d3.select(".spider-chart-svg").remove();
    d3.select(".chart-labels-svg").remove();

    const viewbox =
      window.innerWidth <= 500 ? "-80 -100 600 600" : "-60 0 850 850";

    //Setting up the Svgs
    const chartSvg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("class", "spider-chart-svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", viewbox)
      .style("margin-top", window.innerWidth <= 450 ? "0rem" : "2rem");

    const labelsSvg = d3
      .select(labelsSvgRef.current)
      .append("svg")
      .attr("class", "chart-labels-svg")
      .attr("width", window.innerWidth <= 450 ? window.innerWidth : 450)
      .attr("height", 300);

    renderSpiderChart({
      chartSvg: chartSvg,
      labelsSvg: labelsSvg,
      circleColors: circleColors,
      svgHeight: svgHeight,
      svgWidth: svgWidth,
      t: t,
      carbohydrates: Number(carbohydrateIntake),
      currentWeight: Number(weight),
      dateOfBirth: dateOfBirth,
      exercise: Number(exercise),
      heightCM: Number(height),
      sleep: Number(sleep),
      stressLevel: Number(stressLevel),
      targetWeight: Number(targetWeight),
      workType: Number(work),
    });

    setChartImageData();
  }, [sleep, weight, targetWeight]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="spider-diagram"
      id="spider-diagram"
    >
      <div ref={svgRef} style={{ width: "100%" }} id="chart-container"></div>
      {window.innerWidth < 500 && (
        <div
        className="spider-chart-labels"
          style={{
            display: "flex",
            padding: 20,
            fontSize: 14,
            justifyContent: "space-between",
          }}
        >
          <div className="first-half">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#ff835d",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              1: {t("Movement")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#d92965",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              2: {t("Sports per week")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#f1c200",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              3: {t("Motivation")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#c2a000",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              4: {t("Length in cm")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#c96800",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              5: {t("Hours of sleep per night")}
            </p>
          </div>

          <div className="second-half">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#f17154",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              6: {t("Intake carbohydrates")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#ff835d",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              7: {t("Type of work")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#ae2c30",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                  marginBottom: 10,
                }}
              ></span>
              8: {t("Stress level")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#ff8a00",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              9: {t("Weight in kilograms")}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  display: "block",
                  backgroundColor: "#e75873",
                  width: 7.5,
                  height: 7.5,
                  borderRadius: 30,
                  marginRight: 5,
                }}
              ></span>
              10: {t("Age")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
