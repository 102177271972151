import React from "react";
import WeightReductionForm from "../components/WeightReductionForm";
import LineChart from "../components/LineChart";
import { useNavigate } from "react-router-dom";
import { usePatientContext } from "../context/PatientContext";

function WeightPlan({ notes, currentStage, setCurrentStage }) {
  const navigate = useNavigate();

  const {
    weight,
    targetWeight,
    carbohydrateIntake,
    sleep,
    desiredHoursOfSleep,
    desiredCarbohydrateIntake,
    exercise,
    sportFrequency,
    stepsPerDay,
    firstName,
    lastName,
  } = usePatientContext();

  return (
    <main>
      <section className="page" style={{ padding: "0 0" }}>
        <div className="weight-plan-container">
          <WeightReductionForm
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
            notes={notes}
          />

          <LineChart
            startWeight={weight}
            targetWeight={targetWeight}
            carbohydrate={carbohydrateIntake}
            exercise={exercise}
            sleep={sleep}
            weightLoss={desiredHoursOfSleep}
            lowCarbIntake={desiredCarbohydrateIntake}
            sportFrequency={sportFrequency}
            stepsPerDay={stepsPerDay}
            notes={notes}
          />
        </div>
      </section>
    </main>
  );
}

export default WeightPlan;
