import React from "react";
import { useTranslation } from "react-i18next";
import InputRange from "react-input-range";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Notes from "../layouts/Notes";
import { usePatientContext } from "../../context/PatientContext";
import { revealAnimation } from "./animation";
import Testimonial from "../layouts/Testimonial";

function Routine({ note, path }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const animationProps = useSpring(revealAnimation);
  const {
    sleep,
    exercise,
    setSleep,
    setDesiredHoursOfSleep,
    setExercise,
    setSportFrequency,
  } = usePatientContext();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    sessionStorage.setItem("sleep", sleep);
    sessionStorage.setItem("exercise", exercise);
    sessionStorage.setItem("desiredHourOfSleep", sleep);
    sessionStorage.setItem("sportFrequency", exercise);
    navigate(path);
  };

  return (
    <>
      <animated.div style={animationProps}>
        <form
          onSubmit={handleSubmit}
          style={{ display: "grid", gap: "1.2rem", width: "100%" }}
          className="max-width"
        >
          <div className="input-wrapper">
            <label htmlFor="sleep" className="input-label">
              {t("sleep-label")}?
            </label>
            <div
              style={{
                marginBottom: ".5rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputRange
                minValue={4}
                maxValue={10}
                value={parseInt(sleep)}
                onChange={(newValue) => {
                  setSleep(newValue.toString());
                  setDesiredHoursOfSleep(newValue.toString());
                }}
              />
              <input
                type="number"
                className="range-number-input"
                min={4}
                max={10}
                required
                value={sleep}
                onChange={(event) => {
                  const newValue =
                    event.target.value === ""
                      ? 0
                      : parseInt(event.target.value);
                  setSleep(newValue.toString());
                  setDesiredHoursOfSleep(newValue.toString());
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <label htmlFor="exercise" className="input-label">
              {t("exercise-label")}?
            </label>
            <div
              style={{
                marginBottom: ".5rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputRange
                maxValue={7}
                minValue={0}
                value={parseInt(exercise)}
                onChange={(newValue) => {
                  setExercise(newValue.toString());
                  setSportFrequency(newValue.toString());
                }}
              />
              <input
                type="number"
                className="range-number-input"
                min={0}
                max={7}
                value={exercise}
                required
                onChange={(event) => {
                  const newValue =
                    event.target.value === ""
                      ? 0
                      : parseInt(event.target.value);
                  setExercise(newValue.toString());
                  setSportFrequency(newValue.toString());
                }}
              />
            </div>
          </div>
          <button type="submit" className="button-primary button-full">
            {t("Next")}
          </button>
        </form>

        <Testimonial
          description={"step4-testimonial-description"}
          rounded
          name={"Anne Moazzeni"}
          specialization={"step4-testimonial-specialization"}
          image={"/images/routine.jpg"}
        />
      </animated.div>
    </>
  );
}

export default Routine;
