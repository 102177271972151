import * as d3 from "d3";
interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  currentWeight: number;
  targetWeight: number;
  svgHeight: number;
  svgWidth: number;
  heightCM: number;
  t: any;
}

export default function renderCenterCircle({
  svg,
  svgHeight,
  svgWidth,
  targetWeight,
  t,
  currentWeight,
  heightCM,
}: Props) {
  const svgCenterX = svgWidth / 2;
  const svgCenterY = svgHeight / 2;

  const calculateBMI = (): string => {
    const heightInMeters = heightCM / 100;

    const square = heightInMeters * heightInMeters;

    return Math.floor(currentWeight / square).toString();
  };

  const calculateOverWeight = (): string =>
    currentWeight <= targetWeight
      ? "0"
      : Math.floor(currentWeight - targetWeight).toString();

  function textSize(text: string) {
    if (!d3) return;
    var container = d3.select("body").append("svg");
    container.append("text").text(text).attr("x", -99999).attr("y", -99999);
    var size = container.node().getBBox();
    container.remove();
    return { width: size.width / 2.4, height: size.height };
  }

  svg
    .append("circle")
    .attr("cx", svgWidth / 2)
    .attr("cy", svgHeight / 2)
    .attr("r", 105)
    .attr("stroke", "#333333")
    .attr("fill", "#fbdad4")
    .attr("stroke-width", 2);

  svg
    .append("text")
    .text("BMI")
    .attr("dx", svgCenterX)
    .attr("dy", svgCenterY - 70)
    .style("font-size", window.innerWidth >= 500 ? "1.1rem" : "1.3rem")
    .attr("class", "bmi-center-text");

  svg
    .append("text")
    .text(calculateBMI())
    .attr("dx", svgCenterX)
    .attr("dy", svgCenterY - 40)
    .style("font-size", window.innerWidth >= 500 ? "1.3rem" : "1.5rem")
    .attr("class", "bmi-center-text-value");

  svg
    .append("text")
    .text(t("Over Weight"))
    .attr("dx", svgCenterX)
    .attr("dy", svgCenterY - 12)
    .style("font-size", window.innerWidth >= 500 ? "1.1rem" : "1.3rem")
    .attr("class", "bmi-center-text");

  svg
    .append("text")
    .text(`${calculateOverWeight()} Kg`)
    .attr("dx", svgCenterX + 3)
    .attr("dy", svgCenterY + 22)
    .style("font-size", window.innerWidth >= 500 ? "1.3rem" : "1.5rem")
    .attr("class", "bmi-center-text-value");

  svg
    .append("text")
    .text(t("Target Weight"))
    .attr("dx", svgCenterX)
    .attr("dy", svgCenterY + 55)
    .style("font-size", window.innerWidth >= 500 ? "1.1rem" : "1.3rem")
    .attr("class", "bmi-center-text");

  svg
    .append("text")
    .text(`${targetWeight} Kg`)
    .attr("dx", svgCenterX + 3)
    .attr("dy", svgCenterY + 85)
    .style("font-size", window.innerWidth >= 500 ? "1.3rem" : "1.5rem")
    .attr("class", "bmi-center-text-value");
}
