import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Notes from "../layouts/Notes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { usePatientContext } from "../../context/PatientContext";
import { revealAnimation } from "./animation";

function PersonalInfoSection({ note, path }) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const animationProps = useSpring(revealAnimation);
  const [isMobileView, setIsMobileView] = useState(false);
  const maxDate = new Date(); // Get
  maxDate.setFullYear(maxDate.getFullYear() - 5); //

  const {
    setDateOfBirth,
    dateOfBirth,
    firstName,
    lastName,
    email,
    phone,
    setPhone,
    setEmail,
    setFirstName,
    canWeContact,
    setLastName,
  } = usePatientContext();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setDateOfBirth(date.toISOString());
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (canWeContact == "yes" && phone.length < 7) {
      setError("Please enter the phone number");
      return;
    }

    if (dateOfBirth) {
      sessionStorage.setItem("firstName", firstName);
      sessionStorage.setItem("lastName", lastName);
      sessionStorage.setItem("dateOfBirth", dateOfBirth);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("phone", phone);
      setSelectedDate(null);
      navigate(path);
    }

    setError("Date is not in the correct format");
  };

  useEffect(() => {
    const checkIsMobileView = () => {
      setIsMobileView(window.innerWidth < 550);
    };
    checkIsMobileView();
    window.addEventListener("resize", checkIsMobileView);

    return () => {
      window.removeEventListener("resize", checkIsMobileView);
    };
  }, []);

  const handlePhoneChange = (value: string) => {
    if (value && isValidPhoneNumber(value)) {
      setPhone(value);
    } else {
      setPhone("");
    }
  };

  const isValidPhoneNumber = (value: string): boolean => {
    return /^(\+)?[0-9]{8,}$/.test(value);
  };

  return (
    <>
      <animated.div style={animationProps}>
        <form
          onSubmit={handleSubmit}
          style={{ display: "grid", gap: ".8rem", width: "100%" }}
          className="max-width"
        >
          {error && (
            <p style={{ marginBottom: "1rem", color: "red" }}>
              {t(`${error}`)}
            </p>
          )}
          <div className="input-wrapper">
            <label htmlFor="first-name" className="input-label">
              {t("first-name-label")}?
            </label>

            <input
              className="custom-input"
              name="first-name"
              type="text"
              placeholder="John"
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="last-name" className="input-label">
              {t("last-name-label")}?
            </label>

            <input
              className="custom-input"
              name="last-name"
              type="text"
              placeholder="Doe"
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="email" className="input-label">
              {t("email-label")}?
            </label>
            <input
              className="custom-input"
              type="email"
              name="email"
              placeholder="johndoe@gmail.com"
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </div>

          <div className="input-wrapper" style={{ marginBottom: "1rem" }}>
            <label htmlFor="phone" className="input-label">
              {t("phone-label")}?
            </label>
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              country="nl"
              inputProps={{
                name: "phone",
                required: canWeContact == "yes",
              }}
              onChange={(phone) => handlePhoneChange(phone)}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="date-of-birth" className="input-label">
              {t("date-of-birth-label")}?
            </label>

            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="custom-input"
              name="date-of-birth"
              showMonthDropdown={true}
              showYearDropdown={true}
              dropdownMode="select"
              placeholderText="dd / mm / yyyy"
              open={isMobileView ? false : undefined}
              maxDate={maxDate}
              required
            />
          </div>
          <button type="submit" className="button-primary button-full">
            {t("Next")}
          </button>
        </form>
      </animated.div>
    </>
  );
}

export default PersonalInfoSection;
