export const stressLevels = [
  { value: 4, label: "stress-option1" },
  { value: 3, label: "stress-option2" },
  { value: 2, label: "stress-option3" },
  { value: 1, label: "stress-option4" },
];

export const physicallyActive = [
  { value: 3, label: "work-option-1" },
  { value: 2, label: "work-option-2" },
  { value: 1, label: "work-option-3" },
  { value: 1 / 2, label: "work-option-4" },
];

export const genders = [
  { value: 1, label: "gender-option1" },
  { value: 0, label: "gender-option2" },
];

export const carbohydrateIntake = [
  { value: 3, label: "carbohydrate-option1" },
  { value: 2, label: "carbohydrate-option2" },
  { value: 1, label: "carbohydrate-option3" },
  { value: 0, label: "carbohydrate-option4" },
];

export const GLP = [
  {
    value: "I have no idea what this is.",
    label: "glp1-option1",
  },
  {
    value: "I've read something about it, but I don't know the details.",
    label: "glp1-option2",
  },
  {
    value: "I know a lot about GLP-1 medication.",
    label: "glp1-option3",
  },
];

export const motivationToLooseWeight = [
  { id: 1, label: "1", value: "1" },
  { id: 2, label: "2", value: "2" },
  { id: 3, label: "3", value: "3" },
  { id: 4, label: "4", value: "4" },
  { id: 5, label: "5", value: "5" },
  { id: 6, label: "6", value: "6" },
  { id: 7, label: "7", value: "7" },
  { id: 8, label: "8", value: "8" },
  { id: 9, label: "9", value: "9" },
  { id: 10, label: "10", value: "10" },
];
