import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  className?: string;
};

function Logo({ title, className }: Props) {
  const { t } = useTranslation();
  return (
    <aside className={`logo sticky-right  ${className ?? className}`}>
      <p className="logo__heading">
        <span className="logo__custom-heading">hello</span>
        weight
      </p>

      <p
        className={`logo__subheading font-against  ${
          title === "conclusie" ? "font-small" : ""
        } ${title === "diagram" ? "font-medium" : ""}${
          title === "afslank plan" ? "font-medium" : ""
        }`}
      >
        {t(`${title}`)}
      </p>
    </aside>
  );
}

export default Logo;
