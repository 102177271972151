interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  t: any;
  svgHeight: number;
  startWeight: number;
  targetWeight: number;
}

export default function renderLeftLabels({
  startWeight,
  svg,
  svgHeight,
  t,
  targetWeight,
}: Props) {
  const startWeightMobile = String(t("Start weight")).replace(" ", "");
  const targetWeightMobile = String(t("Target weight")).replace(" ", "");

  const labelsData = [
    {
      name:
        window.innerWidth > 500
          ? ""
          : startWeightMobile.replace(" ", "").slice(0, 5) + "-",
      x: window.innerWidth > 500 ? -90 : -70,
      y: 25,
    },
    {
      name:
        window.innerWidth > 500
          ? t("Start weight")
          : startWeightMobile.slice(5, startWeightMobile.length),
      x:
        window.innerWidth > 500
          ? -90
          : startWeightMobile.length > 11
          ? -90
          : -80,
      y: 45,
    },
    {
      name: `${startWeight} kg`,
      x: window.innerWidth > 500 ? -55 : -65,
      y: 75,
    },
    {
      name: window.innerWidth > 500 ? "" : targetWeightMobile.slice(0, 6) + "-",
      x: window.innerWidth > 500 ? -100 : -80,
      y: svgHeight - 70,
    },
    {
      name:
        window.innerWidth > 500
          ? t("Target weight")
          : targetWeightMobile.slice(6, targetWeightMobile.length),
      x:
        window.innerWidth > 500
          ? -100
          : targetWeightMobile.length > 12
          ? -90
          : -80,
      y: svgHeight - 50,
    },
    {
      name: `${targetWeight} kg`,
      x: window.innerWidth > 500 ? -52 : -65,
      y: svgHeight - 20,
    },
  ];

  const circlesData = [
    { x: window.innerWidth > 500 ? -65 : -75, y: 67, color: "#a13f3e" },
    {
      x: window.innerWidth > 500 ? -72 : -78,
      y: svgHeight - 27,
      color: "#f18b6c",
    },
  ];

  svg
    .selectAll("text")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("g")
        .attr("transform", (d, i) => `translate(${d.x}, ${d.y})`)
        .append("text")
        .text((d) => d.name)
        .style("font-size", window.innerWidth > 500 ? "15" : "1.4rem")
        .style("color", "#111111")
    );

  svg
    .selectAll("circles")
    .data(circlesData)
    .join((enter) =>
      enter
        .append("circle")
        .attr("transform", (d, i) => `translate(${d.x}, ${d.y})`)
        .attr("r", 5)
        .attr("stroke", (d, i) => d.color)
        .attr("fill", (d, i) => d.color)
        .attr("stroke-width", 2)
    );
}
