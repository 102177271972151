interface Props {
  svgWidth: number;
  svgHeight: number;
  circleColors: string[];
  angleToCoordinate: (
    angle: any,
    value: any
  ) => {
    x: number;
    y: number;
  };
  t: any;
}

export default function generateLabelsData({
  svgHeight,
  svgWidth,
  angleToCoordinate,
  t,
  circleColors,
}: Props): labelData[] {
  
  const labels = [
    t("Movement"),
    t("Sports per week"),
    t("Motivation"),
    t("Length in cm"),
    t("Hours of sleep per night"),
    t("Intake carbohydrates"),
    t("Type of work"),
    t("Stress level"),
    t("Weight in kilograms"),
    t("Age"),
  ];

  const totalNumber = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const getRotateDegree = (index) => {
    if (index === 0) return -90;
    else if (index === 1) return -305;
    else if (index === 2) return -340;
    else if (index === 3) return -375;
    else if (index === 4) return -410;
    else if (index === 5) return 90;
    else if (index === 6) return 50;
    else if (index === 7) return 15;
    else if (index === 8) return -15;
    else if (index === 9) return -55;
    else return 0;
  };

  const getLabelCoordinates = (angle, index) => {
    var coordinates = angleToCoordinate(angle, 11);
    if (index === 0) coordinates = { x: coordinates.x + 4, y: coordinates.y };
    else if (index === 1)
      coordinates = {
        x:
          coordinates.x -
          (t("Sports per week") === "Sports per week"
            ? window.innerWidth > 500
              ? 85
              : 70
            : window.innerWidth > 500
            ? 90
            : 75),
        y:
          coordinates.y -
          (t("Sports per week") === "Sports per week"
            ? window.innerWidth > 500
              ? 115
              : 90
            : window.innerWidth > 500
            ? 120
            : 100),
      };
    else if (index === 2)
      coordinates = {
        x:
          coordinates.x -
          (t("Motivation") === "Motivation"
            ? window.innerWidth > 500
              ? 85
              : 70
            : window.innerWidth > 500
            ? 140
            : 115),
        y:
          coordinates.y -
          (t("Motivation") === "Motivation"
            ? window.innerWidth > 500
              ? 25
              : 20
            : window.innerWidth > 500
            ? 45
            : 40),
      };
    else if (index === 3)
      coordinates = {
        x: coordinates.x - (window.innerWidth > 500 ? 110 : 90),
        y: coordinates.y + (window.innerWidth > 500 ? 35 : 25),
      };
    else if (index === 4)
      coordinates = {
        x:
          coordinates.x -
          (t("Hours of sleep per night") === "Hours of sleep per night"
            ? window.innerWidth > 500
              ? 130
              : 105
            : window.innerWidth > 500
            ? 110
            : 95),
        y:
          coordinates.y +
          (t("Hours of sleep per night") === "Hours of sleep per night"
            ? window.innerWidth > 500
              ? 160
              : 130
            : window.innerWidth > 500
            ? 140
            : 120),
      };
    else if (index === 5)
      coordinates = { x: coordinates.x - 5, y: coordinates.y };
    else if (index === 6)
      coordinates = { x: coordinates.x - 2, y: coordinates.y + 5 };
    else if (index === 7)
      coordinates = { x: coordinates.x, y: coordinates.y + 3 };
    else if (index === 8)
      coordinates = { x: coordinates.x, y: coordinates.y + 4 };
    else if (index === 9)
      coordinates = { x: coordinates.x + 2, y: coordinates.y + 2 };
    
    return coordinates;
  };

  const getNumberCoordinates = (angle, index) => {
    var coordinates = angleToCoordinate(angle, 11);

    if (index === 0) return { x: coordinates.x - 5, y: coordinates.y };
    else if (index === 1) return { x: coordinates.x - 5, y: coordinates.y };
    else if (index === 2) return { x: coordinates.x - 6, y: coordinates.y + 5 };
    else if (index === 3) return { x: coordinates.x - 6, y: coordinates.y + 5 };
    else if (index === 4) return { x: coordinates.x - 6, y: coordinates.y + 5 };
    else if (index === 5) return { x: coordinates.x - 4, y: coordinates.y + 6 };
    else if (index === 6)
      return { x: coordinates.x - 4, y: coordinates.y + 10 };
    else if (index === 7) return { x: coordinates.x - 2, y: coordinates.y + 4 };
    else if (index === 8) return { x: coordinates.x - 2, y: coordinates.y + 5 };
    else if (index === 9) return { x: coordinates.x, y: coordinates.y };

    return coordinates;
  };

  const getCircleCoordinates = (angle, i) => {
    const coordinates = angleToCoordinate(angle, 10.5);
    return {
      color: circleColors[i],
      x: coordinates.x,
      y: coordinates.y,
    };
  };

  const data: labelData[] = totalNumber.map((number, i) => {
    let angle = Math.PI / 2 + (2 * Math.PI * i) / totalNumber.length;
    return {
      name: labels[i],
      number: number,
      angle: angle,
      rotateDegree: getRotateDegree(i),
      line_coord: angleToCoordinate(angle, 10),
      label_coord: getLabelCoordinates(angle, i),
      number_coord: getNumberCoordinates(angle, i),
      circle_coord: getCircleCoordinates(angle, i),
    };
  });

  return data;
}
