import React from "react";
import PatientForm from "../components/PatientForm";
import Logo from "../components/Logo";

function Patient({ notes, currentStage, setCurrentStage }) {
  return (
    <main>
      <section className="page">
        <PatientForm
          notes={notes}
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
        />

        <Logo title="vragen" />
      </section>
    </main>
  );
}

export default Patient;
