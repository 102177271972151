import * as d3 from "d3";
import generateLabelsData from "../../helpers/generateLabelsData";
import generatePathData from "../../helpers/generatePathsData";
import renderPaths from "./renderPaths";
import renderAxisLines from "./renderAxisLines";
import renderLabels from "./renderLabels";
import renderLinedCircles from "./renderLinedCircles";
import renderPathCircles from "./renderPathCircles";
import renderCenterCircle from "./renderCenterCircles";
import renderBottomLabels from "./renderBottomLabels";

interface Props {
  chartSvg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  labelsSvg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  svgWidth: number;
  svgHeight: number;
  t: any;
  circleColors: string[];
  sleep: number;
  exercise: number;
  stressLevel: number;
  carbohydrates: number;
  workType: number;
  dateOfBirth: string;
  heightCM: number;
  targetWeight: number;
  currentWeight: number;
}

export default function renderSpiderChart({
  chartSvg,
  svgHeight,
  svgWidth,
  t,
  circleColors,
  carbohydrates,
  currentWeight,
  dateOfBirth,
  exercise,
  heightCM,
  sleep,
  stressLevel,
  targetWeight,
  workType,
  labelsSvg,
}: Props) {
  const radialScale = d3.scaleLinear().domain([0, 11]).range([0, 270]);

  const angleToCoordinate = (angle, value) => {
    let x = Math.cos(angle) * radialScale(value);
    let y = Math.sin(angle) * radialScale(value);
    return { x: svgWidth / 2 + x, y: svgHeight / 2 - y };
  };

  const getPathCoordinates = (data_point) => {
    const totalLabels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    let coordinates = [];
    for (var i = 0; i < totalLabels.length; i++) {
      let ft_name = totalLabels[i];
      let angle = Math.PI / 2 + (2 * Math.PI * i) / totalLabels.length;
      coordinates.push(angleToCoordinate(angle, data_point[ft_name]));
    }
    return coordinates;
  };

  const labelsData: labelData[] = generateLabelsData({
    angleToCoordinate: angleToCoordinate,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
    t: t,
    circleColors: circleColors,
  });

  const pathsData = generatePathData({
    carbohydrates: carbohydrates,
    currentWeight: currentWeight,
    dateOfBirth: dateOfBirth,
    exercise: exercise,
    heightCM: heightCM,
    sleep: sleep,
    stressLevel: stressLevel,
    targetWeight: targetWeight,
    workType: workType,
  });

  // draw the path element
  renderPaths({
    pathsData: pathsData,
    getPathCoordinates: getPathCoordinates,
    svg: chartSvg,
  });

  // draw axis line
  renderAxisLines({
    labelsData: labelsData,
    svg: chartSvg,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
  });

  // draw axis label
  renderLabels({
    labelsData: generateLabelsData({
      angleToCoordinate: angleToCoordinate,
      circleColors: circleColors,
      svgHeight: svgHeight,
      svgWidth: svgWidth,
      t: t,
    }),
    svg: chartSvg,
  });

  // draw outlined circles
  renderLinedCircles({
    radialScale: radialScale,
    svg: chartSvg,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
  });

  // draw circles of path
  renderPathCircles({
    circleColors: circleColors,
    getPathCoordinates: getPathCoordinates,
    pathsData: pathsData,
    svg: chartSvg,
  });

  // draw inner circle
  renderCenterCircle({
    currentWeight: currentWeight,
    heightCM: heightCM,
    svg: chartSvg,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
    t: t,
    targetWeight: targetWeight,
  });

  renderBottomLabels({ labelsData: labelsData, svg: labelsSvg });
}
