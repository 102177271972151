import React, { useEffect, useState } from "react";
import il8n from "../../intake-form/i18n";

function Notes({ notes, location, stage }) {
  const [showNote, setShowNote] = useState(null);

  useEffect(() => {
    const noteForUrl = location.pathname.split("/").pop();

    if (location.pathname === "/bmi/information") {
      setShowNote(null);
    } else {
      const currentNote = notes.find(
        (note) => note.note_for.toLowerCase() === noteForUrl.replace(/-/g, " ")
      );

      if (currentNote) {
        setShowNote(
          il8n.language === "nl"
            ? currentNote.dutch_note
            : currentNote.english_note
        );
      } else {
        setShowNote(null);
      }
    }
  }, [notes, location]);

  if (!showNote) {
    return null;
  }

  const blueNote = notes.filter((note) => note.note_for == "Personal Info");

  return (
    <div
      className="note"
      style={{
        background: `${stage == blueNote[0].step_number ? "#0099FF" : ""}`,
      }}
    >
      <p
        style={{
          display: "flex",
          flexGrow: 1,
          flexWrap: "wrap",
          color: "white",
          paddingRight: "1rem",
        }}
        dangerouslySetInnerHTML={{ __html: showNote }}
      ></p>
      <button
        style={{
          background: "none",
          border: "none",
          fontSize: "1.5rem",
          color: "white",
          cursor: "pointer",
          padding: "5px",
        }}
        onClick={() => {
          setShowNote(null);
        }}
      >
        x
      </button>
    </div>
  );
}

export default Notes;
