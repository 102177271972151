import * as d3 from "d3";

interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  lineData: any;
  svgHeight: number;
  svgWidth: number;
  t: any;
}
export default function renderLine({
  lineData,
  svg,
  svgHeight,
  svgWidth,
  t,
}: Props) {
  const xScale = d3
    .scaleLinear()
    .domain([0, lineData[0].length - 1])
    .range([0, svgWidth]);

  //const yScale = d3.scaleLinear().domain([0, svgHeight]).range([0, svgHeight]);
  const yScale = d3
    .scaleLinear()
    .domain([-0.1 * svgHeight, svgHeight])
    .range([0, svgHeight]);

  const generatedScaledLine = d3
    .line()
    .x((d, i) => xScale(i))
    .y(yScale)
    .curve(d3.curveNatural);

  const withoutMedicationLine = svg.selectAll(".line").data([lineData[0]]);

  const middleIndexWithoutMedication = Math.floor(lineData[0].length / 2);
  const middleIndexWithMedication = Math.floor(lineData[1].length / 2);

  const middlePointWithoutMedication =
    lineData[0][middleIndexWithoutMedication];
  const middlePointWithMedication = lineData[1][middleIndexWithMedication];

  const middleXWithoutMedication = xScale(middleIndexWithoutMedication);
  const middleYWithoutMedication = yScale(middlePointWithoutMedication);

  const middleXWithMedication = xScale(middleIndexWithMedication);
  const middleYWithMedication = yScale(middlePointWithMedication);
  const labelsData = [
    {
      y: middleYWithoutMedication - 7,
      x: middleXWithoutMedication + 12, // adding 10 for some space from line
      name: t("NoMedication"),
      color: "#db755f",
    },
    {
      y: middleYWithMedication - 7,
      x: middleXWithMedication + 12, // adding 10 for some space from line
      name: t("Medication"),
      color: "#bd584e",
    },
  ];

  withoutMedicationLine.join((enter) =>
    enter
      .append("path")
      .attr("d", (d) => generatedScaledLine(d))
      .attr("fill", "none")
      .attr("stroke", "#db755f")
      .attr("stroke-width", 8)
  );

  const withMedicationAxisLine = svg.selectAll(".line").data([lineData[1]]);

  withMedicationAxisLine.join((enter) =>
    enter
      .append("path")
      .attr("d", (d) => generatedScaledLine(d))
      .attr("fill", "none")
      .attr("stroke", "#bd584e")
      .attr("stroke-width", 8)
  );

  svg
    .selectAll("linesText")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("g")
        .attr("transform", (d, i) => `translate(${d.x}, ${d.y})`)
        .append("text")
        .text((d) => d.name)
        .style("font-size", window.innerWidth > 500 ? "13" : "1.2rem")
        .style("color", "#111111")
    );

  svg
    .selectAll("linesCircles")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("circle")
        .attr("transform", (d, i) => `translate(${d.x - 10}, ${d.y - 4})`)
        .attr("r", 4)
        .attr("stroke", (d, i) => d.color)
        .attr("fill", (d, i) => d.color)
        .attr("stroke-width", 2)
    );
}
