import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Information({ path, note }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const currentLanguage = i18n.language;
  const noteToDisplay =
    currentLanguage === "nl" ? note?.dutch_note : note?.english_note;
  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (!isLoading) {
    return (
      <div className="center" style={{ position: "relative" }}>
        <div
          style={{
            maxWidth: "700px",
            textAlign: "justify",
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: noteToDisplay }} />
          <button
            className="button-primary button-full"
            style={{ marginTop: "1rem" }}
            onClick={() => {
              navigate(path);
            }}
          >
            {t("Next")}
          </button>
        </div>
      </div>
    );
  }
}

export default Information;
