import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ProgressBar({ currentStage, setCurrentStage, notes }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const currentNote = notes.find(
      (note) => location.pathname === `/${note.path}`
    );

    if (currentNote) {
      setCurrentStage(currentNote.step_number);
    } else {
      const firstStepNote = notes.find((note) => note.step_number === 1);
      if (firstStepNote) {
        setCurrentStage(1);
        navigate(`/${firstStepNote.path}`);
      }
    }
  }, [location.pathname, notes]);

  return (
    <div style={{ width: "100%" }} className="max-width">
      <div className="form-header">
        <div className="form-header__left">
          <button
            style={{
              fontSize: ".8rem",
              border: "none",
              marginRight: ".4rem",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            {t("Previous")}
          </button>
        </div>
        <p>
          {currentStage} / {notes.length}
        </p>
      </div>

      <progress
        style={{
          width: "100%",
        }}
        max={notes.length}
        value={currentStage}
      ></progress>
    </div>
  );
}

export default ProgressBar;
