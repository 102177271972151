import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: { translation: {} }, // Empty initial translation
  nl: { translation: {} },
};
let preferredLanguage = localStorage.getItem("preferredLanguage") || "nl";

const fetchTranslations = async (language) => {
  try {
    const response = await fetch(`/translations?language=${language}`);
    if (!response.ok) {
      throw new Error("Failed to fetch translations");
    }
    const translations = await response.json();

    // Convert the array of translation objects into an object with key-value pairs
    const translationObject = {};
    translations.forEach((translation) => {
      translationObject[translation.key] = translation.value;
    });

    return translationObject;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const updateTranslations = async () => {
  const translations = await fetchTranslations(preferredLanguage);
  if (translations) {
    console.log(translations);
    i18n.addResourceBundle(preferredLanguage, "translation", translations);
  }
};

const initializeTranslations = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: preferredLanguage,
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false,
    },
  });

  updateTranslations(); // Initial fetching of translations

  // Listen for changes to the preferred language and update translations accordingly
  i18n.on("languageChanged", (newLanguage) => {
    preferredLanguage = newLanguage;
    updateTranslations();
  });
};

initializeTranslations();

export default i18n;
