import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  description?: string;
  name?: string;
  specialization?: string;
  image?: string;
  noDote?: boolean;
  rounded?: boolean;
  scaleImage?: boolean;
  phoneNumber?: React.ReactNode;
};

function Testimonial({
  description,
  name,
  specialization,
  image,
  noDote,
  scaleImage,
  phoneNumber,
  rounded,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="testimonials__container">
      <div className="testimonials">
        <div className="testimonials__grid">
          <div>
            {description && (
              <p className="testimonials__description">
                <q
                  dangerouslySetInnerHTML={{
                    __html: t(`${description}`) + `${!noDote ? "." : ""}`,
                  }}
                ></q>
              </p>
            )}
            {name && <h2 className="testimonials__heading">{t(`${name}`)}</h2>}
            {specialization && (
              <p className="testimonials__sub-heading">
                {t(`${specialization}`)}
              </p>
            )}
            {phoneNumber && (
              <p
                className="testimonials__sub-heading"
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                {phoneNumber}
              </p>
            )}
          </div>
          <div className="image">
            <img
              src={image}
              style={{ borderRadius: `${rounded ? "50%" : ""}` }}
              className={`${
                scaleImage ? "testimonials__scale-image" : "testimonials__image"
              }`}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
