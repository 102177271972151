import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Notes from "../layouts/Notes";
import { usePatientContext } from "../../context/PatientContext";
import { revealAnimation } from "./animation";
import Testimonial from "../layouts/Testimonial";

function GenderSection({ genders, note, path }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const animationProps = useSpring(revealAnimation);
  const { setGender } = usePatientContext();

  return (
    <>
      <animated.div style={animationProps}>
        <form style={{ display: "grid", width: "100%" }} className="max-width">
          <div className="input-wrapper">
            <p className="input-label">{t("gender-label")}?</p>
            <aside className="radio-container-vertical">
              {genders.map((option, index) => (
                <div className="radio-input-wrapper" key={index}>
                  <input
                    type="radio"
                    className="radio-input"
                    name="gender"
                    value={option.value}
                    onChange={(event) => {
                      setGender(event.target.value);
                      sessionStorage.setItem("gender", event.target.value);

                      navigate(path);
                    }}
                    required
                  />

                  <label>{t(`${option.label}`)}</label>
                </div>
              ))}
            </aside>
          </div>
        </form>
        <Testimonial
          description={"step2-testimonial-description"}
          name={"Drs. Marit Van Boreen"}
          specialization={"step2-testimonial-specialization"}
          image={"/images/gender.jpg"}
          rounded
        />
      </animated.div>
    </>
  );
}

export default GenderSection;
