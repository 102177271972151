import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import GenerateSections from "./SectionData";
import { usePatientContext } from "../context/PatientContext";
import Notes from "./layouts/Notes";
import ProgressBar from "./layouts/ProgressBar";

function BmiForm({ notes, currentStage, setCurrentStage }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const { firstName, lastName } = usePatientContext();

  const sections = GenerateSections(notes);

  if (!isLoading) {
    return (
      <div className="bmi-form">
        <Notes notes={notes} location={location} stage={currentStage} />
        <p
          style={{
            marginTop: ".5rem",
            textAlign: "right",
            padding: ".5rem",
            fontSize: ".9rem",
            fontWeight: "bold",
          }}
        >
          {firstName} {lastName}
        </p>
        <section className="form-container">
          <ProgressBar
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
            notes={notes}
          />
          <div className="form">
            <Routes location={location}>
              {sections.map((section: any, index) => (
                <Route
                  key={index}
                  path={section.path}
                  element={React.createElement(
                    section.component,
                    section.props
                  )}
                />
              ))}
            </Routes>
          </div>
        </section>
      </div>
    );
  }
}

export default BmiForm;
