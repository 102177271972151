import React, { useEffect, useState } from "react";
import RadarChart from "../components/RadarChart";
import { useSSR, useTranslation } from "react-i18next";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../components/layouts/ProgressBar";

interface Props {
  firstName: string;
  lastName: string;
  notes: any;
  currentStage: number;
  setCurrentStage: any;
}

function SpiderChart({
  firstName,
  lastName,
  notes,
  currentStage,
  setCurrentStage,
}: Props) {
  const navigate = useNavigate();
  const [route, setRoute] = useState("");

  const { t } = useTranslation();

  const getNextStepPath = (notes, currentNoteFor) => {
    const currentIndex = notes.findIndex(
      (note) => note.note_for === currentNoteFor
    );
    const nextNoteFor = notes[currentIndex + 1];
    return nextNoteFor ? `${nextNoteFor.path}` : "terms";
  };

  useEffect(() => {
    setRoute(getNextStepPath(notes, "Spider Chart"));
  }, []);

  return (
    <main>
      <section className="page">
        <div
          className="center"
          style={{ position: "relative", paddingTop: "15px" }}
        >
          <p
            className="username"
            style={{
              width: "100%",
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "0.9rem",
              paddingRight: "20px",
              paddingBottom: "15px",
            }}
          >
            {firstName} {lastName}
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              padding: "15px",
            }}
          >
            <ProgressBar
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              notes={notes}
            />
          </div>

          <RadarChart />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "500px",
              padding: window.innerWidth <= 600 ? "1rem" : "",
            }}
          >
            <button
              className={`button-primary button-full`}
              onClick={() => {
                navigate(`/${route}`);
              }}
            >
              {t("Next")}
            </button>
          </div>
        </div>

        <Logo title="diagram" />
      </section>
    </main>
  );
}

export default SpiderChart;
