import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

interface PatientState {
  medication: string | null;
  setMedication: Dispatch<SetStateAction<string | null>>;
  dateOfBirth: string | null;
  setDateOfBirth: Dispatch<SetStateAction<string | null>>;
  weight: string | null;
  setWeight: Dispatch<SetStateAction<string | null>>;
  targetWeight: string | null;
  setTargetWeight: Dispatch<SetStateAction<string | null>>;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  height: string | null;
  setHeight: Dispatch<SetStateAction<string | null>>;
  sleep: string | null;
  setSleep: Dispatch<SetStateAction<string | null>>;
  exercise: string | null;
  setExercise: Dispatch<SetStateAction<string | null>>;
  desiredCarbohydrateIntake: string | null;
  setDesiredCarbohydrateIntake: Dispatch<SetStateAction<string | null>>;
  work: string | null;
  setWork: Dispatch<SetStateAction<string | null>>;
  motivation: string | null;
  setMotivation: Dispatch<SetStateAction<string | null>>;
  desiredHoursOfSleep: string | null;
  setDesiredHoursOfSleep: Dispatch<SetStateAction<string | null>>;
  carbohydrateIntake: string | null;
  setCarbohydrateIntake: Dispatch<SetStateAction<string | null>>;
  sportFrequency: string | null;
  setSportFrequency: Dispatch<SetStateAction<string | null>>;
  stepsPerDay: string | null;
  setStepsPerDay: Dispatch<SetStateAction<string | null>>;
  stressLevel: string;
  setStressLevel: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  spiderChartImage: string;
  setSpiderChartImage: Dispatch<SetStateAction<string>>;
  linearChartImage: string;
  setLinearChartImage: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  canWeContact: string;
  setCanWeContact: Dispatch<SetStateAction<string>>;
}

const PatientContext = createContext<PatientState | undefined>(undefined);

export function usePatientContext() {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatientContext must be used within a PatientProvider");
  }
  return context;
}

interface PatientProviderProps {
  children: ReactNode;
}

export function PatientProvider({ children }: PatientProviderProps) {
  const [medication, setMedication] = useState<string | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
  const [weight, setWeight] = useState<string | null>("70");
  const [targetWeight, setTargetWeight] = useState<string | null>("55");
  const [gender, setGender] = useState<string>("");
  const [height, setHeight] = useState<string | null>("180");
  const [sleep, setSleep] = useState<string | null>("6");
  const [exercise, setExercise] = useState<string | null>("3");
  const [desiredCarbohydrateIntake, setDesiredCarbohydrateIntake] = useState<
    string | null
  >("2");
  const [work, setWork] = useState<string | null>("");
  const [motivation, setMotivation] = useState<string | null>("");
  const [desiredHoursOfSleep, setDesiredHoursOfSleep] = useState<string | null>(
    "6"
  );
  const [carbohydrateIntake, setCarbohydrateIntake] = useState<string | null>(
    "2"
  );
  const [sportFrequency, setSportFrequency] = useState<string | null>("3");
  const [stepsPerDay, setStepsPerDay] = useState<string | null>("5000");
  const [stressLevel, setStressLevel] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [spiderChartImage, setSpiderChartImage] = useState<string>("");
  const [canWeContact, setCanWeContact] = useState<string>("yes");
  const [linearChartImage, setLinearChartImage] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    const storedDateOfBirth = sessionStorage.getItem("dateOfBirth");
    const storedWeight = sessionStorage.getItem("weight");
    const storedTargetWeight = sessionStorage.getItem("targetWeight");
    const storedHeight = sessionStorage.getItem("height");
    const storedCarbohydrate = sessionStorage.getItem("carbohydrate");
    const storedSleep = sessionStorage.getItem("sleep");
    const storedEmail = sessionStorage.getItem("email");
    const storedExercise = sessionStorage.getItem("exercise");
    const storedWork = sessionStorage.getItem("work");
    const storedMotivation = sessionStorage.getItem("motivation");
    const storedStressLevel = sessionStorage.getItem("stressLevel");
    const storedFirstName = sessionStorage.getItem("firstName");
    const storedLastName = sessionStorage.getItem("lastName");
    const storedLowCarbIntake = sessionStorage.getItem(
      "desiredCarbohydrateIntake"
    );
    const storedWeightLoss = sessionStorage.getItem("desiredHourOfSleep");
    const storedSportFrequency = sessionStorage.getItem("SportFrequency");
    const storedStepsPerDay = sessionStorage.getItem("stepsPerDay");
    const storedMedication = sessionStorage.getItem("medication");
    const storedGender = sessionStorage.getItem("gender");
    const storedPhone = sessionStorage.getItem("phone");
    const storedCanWeContact = sessionStorage.getItem("canWeContact");

    if (storedCanWeContact !== null && storedCanWeContact !== undefined) {
      setCanWeContact(storedCanWeContact);
    }

    if (storedPhone !== null && storedPhone !== undefined) {
      setPhone(storedPhone);
    }

    if (storedGender !== null && storedGender !== undefined) {
      setGender(storedGender);
    }

    if (storedEmail !== null && storedEmail !== undefined) {
      setEmail(storedEmail);
    }

    if (storedDateOfBirth !== null && storedDateOfBirth !== undefined) {
      setDateOfBirth(storedDateOfBirth);
    }

    if (storedWeight !== null && storedWeight !== undefined) {
      setWeight(storedWeight);
    }
    if (storedTargetWeight !== null && storedTargetWeight !== undefined) {
      setTargetWeight(storedTargetWeight);
    }
    if (storedHeight !== null && storedHeight !== undefined) {
      setHeight(storedHeight);
    }
    if (storedCarbohydrate !== null && storedCarbohydrate !== undefined) {
      setCarbohydrateIntake(storedCarbohydrate);
    }
    if (storedSleep !== null && storedSleep !== undefined) {
      setSleep(storedSleep);
    }
    if (storedExercise !== null && storedExercise !== undefined) {
      setExercise(storedExercise);
    }
    if (storedWork !== null && storedWork !== undefined) {
      setWork(storedWork);
    }
    if (storedMotivation !== null && storedMotivation !== undefined) {
      setMotivation(storedMotivation);
    }
    if (storedStressLevel !== null && storedStressLevel !== undefined) {
      setStressLevel(storedStressLevel);
    }
    if (storedFirstName !== null && storedFirstName !== undefined) {
      setFirstName(storedFirstName);
    }
    if (storedLastName !== null && storedLastName !== undefined) {
      setLastName(storedLastName);
    }
    if (storedLowCarbIntake !== null && storedLowCarbIntake !== undefined) {
      setDesiredCarbohydrateIntake(storedLowCarbIntake);
    }
    if (storedWeightLoss !== null && storedWeightLoss !== undefined) {
      setDesiredHoursOfSleep(storedWeightLoss);
    }
    if (storedSportFrequency !== null && storedSportFrequency !== undefined) {
      setSportFrequency(storedSportFrequency);
    }
    if (storedStepsPerDay !== null && storedStepsPerDay !== undefined) {
      setStepsPerDay(storedStepsPerDay);
    }
    if (storedMedication !== null && storedMedication !== undefined) {
      setMedication(storedMedication);
    }
  }, []);

  const patientState: PatientState = {
    medication,
    setMedication,
    dateOfBirth,
    setDateOfBirth,
    weight,
    setWeight,
    targetWeight,
    setTargetWeight,
    gender,
    setGender,
    height,
    setHeight,
    sleep,
    setSleep,
    exercise,
    setExercise,
    carbohydrateIntake,
    setCarbohydrateIntake,
    work,
    setWork,
    motivation,
    setMotivation,
    desiredHoursOfSleep,
    setDesiredHoursOfSleep,
    desiredCarbohydrateIntake,
    setDesiredCarbohydrateIntake,
    sportFrequency,
    setSportFrequency,
    stepsPerDay,
    setStepsPerDay,
    stressLevel,
    setStressLevel,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setSpiderChartImage,
    spiderChartImage,
    linearChartImage,
    setLinearChartImage,
    phone,
    setPhone,
    canWeContact,
    setCanWeContact,
  };

  return (
    <PatientContext.Provider value={patientState}>
      {children}
    </PatientContext.Provider>
  );
}
