import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Instruction({ path }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        maxWidth: "500px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>
        <b>{t("instruction-label")}</b>
      </p>
      <ul style={{ padding: "0 .6rem", display: "grid", gap: "5px" }}>
        <li>{t("instruction1")}</li>
        <li>{t("instruction2")}</li>
        <li>{t("instruction3")}</li>
      </ul>
      <p
        style={{ marginBottom: "10px" }}
        dangerouslySetInnerHTML={{
          __html: t("instruction4"),
        }}
      />
      <button
        type="button"
        onClick={() => {
          navigate(path);
        }}
        className="button-primary"
        style={{ width: "100%" }}
      >
        {t("Next")}
      </button>
    </div>
  );
}

export default Instruction;
