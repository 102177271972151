import { AxiosResponse } from "axios";
import renderLeftLabels from "./renderLeftLabels";
import renderLine from "./renderLine";
import renderMonthsLabels from "./renderMonthsLabels";
import generateMonthsLabelsData from "../../helpers/generateMonthsLabelsData";
import generateLineData from "../../helpers/generateLineData";

interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  res: AxiosResponse<any, any>;
  t: any;
  svgWidth: number;
  svgHeight: number;
  startWeight: number;
  targetWeight: number;
  weightLoss: number;
  sleep: number;
  lowCarbIntake: number;
  carbohydrate: number;
  sportFrequency: number;
  exercise: number;
  stepsPerDay: number;
}

export default function renderLineChart({
  svg,
  res,
  carbohydrate,
  exercise,
  lowCarbIntake,
  sleep,
  startWeight,
  stepsPerDay,
  sportFrequency,
  svgHeight,
  targetWeight,
  weightLoss,
  t,
  svgWidth,
}: Props) {
  const lineData = generateLineData({
    carbohydrate: Number(carbohydrate),
    exercise: Number(exercise),
    lowCarbIntake: Number(lowCarbIntake),
    points_for_carbs: Number(res.data.meal),
    points_for_exercise: Number(res.data.exercise),
    points_for_medication: Number(res.data.medication),
    points_for_sleep: Number(res.data.sleep),
    points_for_steps: Number(res.data.steps),
    sleep: Number(sleep),
    startWeight: Number(startWeight),
    stepsPerDay: Number(stepsPerDay),
    sportFrequency: Number(sportFrequency),
    svgHeight: svgHeight,
    targetWeight: Number(targetWeight),
    weightLoss: Number(weightLoss),
  });

  const monthsLabelsData = generateMonthsLabelsData(svgWidth, t);

  renderLeftLabels({
    startWeight: startWeight,
    svg: svg,
    svgHeight: svgHeight,
    t: t,
    targetWeight: targetWeight,
  });

  renderLine({
    lineData: lineData,
    svg: svg,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
    t: t,
  });

  renderMonthsLabels({
    data: monthsLabelsData,
    svg: svg,
    svgHeight: svgHeight,
    svgWidth: svgWidth,
    t: t,
  });
}
