import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Notes from "../layouts/Notes";
import { usePatientContext } from "../../context/PatientContext";
import { revealAnimation } from "./animation";
import Testimonial from "../layouts/Testimonial";

function Work({ physicallyActive, note, path }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const animationProps = useSpring(revealAnimation);
  const { setWork } = usePatientContext();

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setWork(selectedValue);
    sessionStorage.setItem("work", selectedValue);
    navigate(path);
  };

  return (
    <>
      <animated.div style={animationProps}>
        <form style={{ display: "grid", width: "100%" }} className="max-width">
          <div className="input-wrapper">
            <p className="input-label">{t("work-label")}?</p>
            <aside className="radio-button radio-container-vertical">
              {physicallyActive.map((option) => (
                <div className="radio-input-wrapper" key={option.value}>
                  <input
                    type="radio"
                    className="radio-input"
                    name="work"
                    value={option.value}
                    onChange={handleChange}
                    required
                  />
                  <label>{t(`${option.label}`)}</label>
                </div>
              ))}
            </aside>
          </div>
        </form>
        <Testimonial
          description={"step6-testimonial-description"}
          image={"/images/work.png"}
        />
      </animated.div>
    </>
  );
}

export default Work;
