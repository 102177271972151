import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePatientContext } from "../context/PatientContext";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import ProgressBar from "./layouts/ProgressBar";
import Logo from "./Logo";
import Testimonial from "./layouts/Testimonial";

function Terms({ notes, currentStage, setCurrentStage }) {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const getPreferredLanguage = (defaultValue = "nl") => {
    return localStorage.getItem("preferredLanguage") || defaultValue;
  };

  const preferredLanguage = useMemo(() => getPreferredLanguage(), []);

  const {
    dateOfBirth,
    firstName,
    lastName,
    phone,
    email,
    weight,
    targetWeight,
    height,
    carbohydrateIntake,
    sleep,
    exercise,
    work,
    motivation,
    medication,
    canWeContact,
    desiredCarbohydrateIntake,
    desiredHoursOfSleep,
    sportFrequency,
    stepsPerDay,
    stressLevel,
    spiderChartImage,
    linearChartImage,
    gender,
    setFirstName,
    setLastName,
    setSleep,
    setExercise,
    setGender,
    setWeight,
    setTargetWeight,
    setHeight,
    setEmail,
    setPhone,
  } = usePatientContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const SubmitHandler = async (event) => {
    setIsButtonDisabled(true);

    try {
      var jsDate = new Date(dateOfBirth);
      var formattedDate = jsDate.toISOString().split("T")[0];
      const formData = {
        first_name: firstName.trim().toLowerCase(),
        last_name: lastName.trim().toLowerCase(),
        email: email.trim(),
        phone_number: phone.trim(),
        current_weight: parseFloat(weight),
        target_weight: parseFloat(targetWeight),
        date_of_birth: formattedDate,
        height: parseFloat(height),
        carbohydrate_intake: parseFloat(carbohydrateIntake),
        hours_of_sleep: parseInt(sleep),
        exercise_per_week: parseInt(exercise),
        work_type: parseInt(work),
        motivation_to_lose_weight: motivation,
        lose_weight_with_medication: medication === "yes",
        can_we_contact: canWeContact === "yes",
        desired_hours_of_sleep: parseInt(desiredHoursOfSleep),
        desired_carbohydrate_intake: parseFloat(desiredCarbohydrateIntake),
        sports_frequency_per_week: parseInt(sportFrequency),
        steps_per_day: parseInt(stepsPerDay),
        stress_level: parseInt(stressLevel),
        gender: parseInt(gender),
        language: preferredLanguage,
        spider_chart_image: spiderChartImage,
        linear_chart_image: linearChartImage,
      };
      const response = await fetch("/patients", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ patient: formData }),
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.clear();
        setFirstName("");
        setLastName("");
        setExercise("1");
        setSleep("4");
        setGender("");
        setWeight("40");
        setTargetWeight("30");
        setHeight("60");
        navigate("/final");
        setEmail("");
        setPhone("");
      } else {
        const error = await response.json();
      }
    } catch (error) {
      setError("Something went wrong during the survey");
    }
    setIsButtonDisabled(false);
  };

  return (
    <main>
      <section className="page">
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 .5rem",
            alignItems: "center",
            textAlign: "center",
            width: "max-content",
            maxWidth: "550px",
          }}
        >
          <ProgressBar
            notes={notes}
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
          />
          <Testimonial
            description={"step13-testimonial-description"}
            name={"step13-testimonial-heading"}
            specialization={"step13-testimonial-specialization"}
            image={"/images/terms.png"}
            noDote
          />
          <p style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            {t("terms-label")}
          </p>

          <button
            type="submit"
            onClick={SubmitHandler}
            className="button-primary"
            style={{ width: "100%" }}
            disabled={isButtonDisabled}
          >
            {isButtonDisabled && (
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#007bff", "#007bff", "#007bff", "#007bff", "#007bff"]}
              />
            )}
            {t("Submit")}
          </button>
          <p
            style={{ fontWeight: "500", fontSize: ".75rem" }}
            dangerouslySetInnerHTML={{
              __html: t("terms-important"),
            }}
          />
        </div>
        <Logo title="conclusie" />
      </section>
    </main>
  );
}

export default Terms;
