import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import axios from "axios";
import renderLineChart from "./linearChartComponents/renderLineChart";
import domtoimage from "dom-to-image-more";
import { usePatientContext } from "../context/PatientContext";
import { Navigate, useNavigate } from "react-router-dom";

interface Props {
  startWeight: string;
  targetWeight: string;
  sleep: string;
  exercise: string;
  carbohydrate: string;
  weightLoss: string;
  sportFrequency: string;
  lowCarbIntake: string;
  stepsPerDay: string;
  notes: any;
}

export default function LineChart({
  carbohydrate,
  exercise,
  lowCarbIntake,
  sportFrequency,
  sleep,
  weightLoss,
  startWeight,
  targetWeight,
  stepsPerDay,

  notes,
}: Props) {
  const svgRef = useRef();
  const { setLinearChartImage } = usePatientContext();
  const [svgWidth] = useState<number>(600);
  const [svgHeight] = useState<number>(350);
  const [route, setRoute] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  });

  const setChartImageData = () => {
    var node = document.getElementById("chart-container");
    node.style.backgroundColor = "#eaebe7";

    domtoimage
      .toPng(node)
      .then((dataUrl) => {
        setLinearChartImage(dataUrl);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getNextStepPath = (notes, currentNoteFor) => {
    const currentIndex = notes.findIndex(
      (note) => note.note_for === currentNoteFor
    );
    const nextNoteFor = notes[currentIndex + 1];
    return nextNoteFor ? `${nextNoteFor.path}` : "terms";
  };

  useEffect(() => {
    axios.post(`/get-settings`).then((res) => {
      d3.select(".line-chart-svg").remove();

      const svg = d3
        .select(svgRef.current)
        .append("svg")
        .attr("class", "line-chart-svg")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("viewBox", "-110 0 750 400");
      // -100 0 600 300
      //-150 0 800 400

      // .attr("transform", `scale(${window.innerWidth > 500 ? 1 : 0.7})`)
      // .style("margin-left", window.innerWidth <= 500 ? "0" : "6rem");

      // background color
      svg
        .append("defs")
        .append("linearGradient")
        .attr("id", "grad")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%")
        .selectAll("stop")
        .data([
          { offset: "63%", color: "#f6dcd6" },
          { offset: "75%", color: "#e6e1dc" },
        ])
        .enter()
        .append("stop")
        .attr("offset", (d) => d.offset)
        .attr("stop-color", (d) => d.color);

      svg
        .append("rect")
        .attr("width", "600")
        .attr("height", "350")
        .attr("fill", "url(#grad)");

      svg
        .append("rect")
        .attr("width", "600")
        .attr("height", "350")
        .attr("fill", "none")
        .attr("stroke", "#333333")
        .attr("stroke-width", "2")
        .attr("stroke-dasharray", "3");

      renderLineChart({
        carbohydrate: Number(carbohydrate),
        exercise: Number(exercise),
        lowCarbIntake: Number(lowCarbIntake),
        res: res,
        sleep: Number(sleep),
        startWeight: Number(startWeight),
        stepsPerDay: Number(stepsPerDay),
        sportFrequency: Number(sportFrequency),
        svg: svg,
        svgHeight: svgHeight,
        svgWidth: svgWidth,
        t: t,
        targetWeight: Number(targetWeight),
        weightLoss: Number(weightLoss),
      });
      setChartImageData();
    });
  }, [weightLoss, sportFrequency, lowCarbIntake, stepsPerDay]);

  useEffect(() => {
    setRoute(getNextStepPath(notes, "Weight Plan"));
  }, []);

  return (
    <>
      <div
        className="line-chart-container"
        id="chart-container"
        style={{ width: "100%" }}
        ref={svgRef}
      ></div>
      <div
        style={{
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          display: windowWidth > 1450 ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => {
            navigate(`/${route}`);
          }}
          className="button-primary button-full"
        >
          {t("Next")}
        </button>
      </div>
    </>
  );
}
