interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  pathsData;
  getPathCoordinates: (data_point: any) => any[];
  circleColors: string[];
}

export default function renderPathCircles({
  pathsData,
  svg,
  getPathCoordinates,
  circleColors,
}: Props) {
  const circlesData = getPathCoordinates(pathsData[0]);

  svg
    .selectAll(".circles")
    .data(circlesData)
    .join((enter) =>
      enter
        .append("circle")
        .attr("transform", (d, i) => `translate(${d.x}, ${d.y})`)
        .attr("r", 8)
        .attr("stroke", (d, i) => circleColors[i])
        .attr("fill", (d, i) => circleColors[i])
        .attr("stroke-width", 2)
    );
}
