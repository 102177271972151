import * as d3 from "d3";

interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  getPathCoordinates: (data_point: any) => any[];
  pathsData;
}

export default function renderPaths({
  svg,
  pathsData,
  getPathCoordinates,
}: Props) {
  var line = d3
    .line()
    .curve(d3.curveCardinal)
    .x((d) => d.x)
    .y((d) => d.y);

  svg
    .selectAll("path")
    .data(pathsData)
    .join((enter) =>
      enter
        .append("path")
        .datum((d) => getPathCoordinates(d))
        .attr("d", line)
        .attr("stroke-width", 3)
        .attr("stroke", "#dfbeb9")
        .attr("fill", "#dfbeb9")
        .attr("opacity", 0.8)
    );
}
