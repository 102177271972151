import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dashboard from "../pages/Dashboard";
import Bmi from "../pages/Bmi";
import SpiderChart from "../pages/SpiderChart";
import WeightPlan from "../pages/WeightPlan";
import Patient from "../pages/Patient";
import FinalPage from "../pages/FinalPage";
import { usePatientContext } from "../context/PatientContext";
import axios from "axios";
import Terms from "../components/Terms";
import PageTracker from "../helpers/PageTracker";

const App = () => {
  const [notes, setNotes] = useState([]);
  const [currentStage, setCurrentStage] = useState(0);
  const { pathname } = window.location;
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("preferredLanguage", language);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get("/notes");
        setNotes(response.data.notes);
      } catch (error) {}
    };

    fetchNotes();
  }, []);

  const { firstName, lastName } = usePatientContext();

  if (notes.length > 0) {
    return (
      <Router>
        <PageTracker />

        <Routes>
          <Route
            path="/"
            element={<Dashboard handleChangeLanguage={handleChangeLanguage} />}
          />
          <Route
            path="/bmi/*"
            element={
              <Bmi
                notes={notes}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
            }
          />
          <Route
            path="/spider-chart"
            element={
              <SpiderChart
                firstName={firstName}
                lastName={lastName}
                notes={notes}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
            }
          />
          <Route
            path="/weight-plan"
            element={
              <WeightPlan
                notes={notes}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
            }
          />
          <Route
            path="/patient"
            element={
              <Patient
                notes={notes}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
            }
          />

          <Route
            path="/terms"
            element={
              <Terms
                notes={notes}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
              />
            }
          />
          <Route path="/final" element={<FinalPage />} />
        </Routes>
      </Router>
    );
  }
};

export default App;
function createBrowserHistory() {
  throw new Error("Function not implemented.");
}
