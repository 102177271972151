import PersonalInfoSection from "./formComponents/PersonalInfoSection";
import WeightSection from "./formComponents/WeightSection";
import Routine from "./formComponents/Routine";
import CarbohydrateIntake from "./formComponents/CarbohydrateIntake";
import Stress from "./formComponents/Stress";
import Work from "./formComponents/Work";
import MotivationField from "./formComponents/MotivationField";
import GenderSection from "./formComponents/GenderSection";
import Information from "../pages/Information";

import {
  stressLevels,
  physicallyActive,
  genders,
  carbohydrateIntake,
  motivationToLooseWeight,
  GLP,
} from "./data";
import Instruction from "./formComponents/Instruction";

const GenerateSections = (notes) => {
  const getNextStepPath = (notes, currentNoteFor) => {
    const currentIndex = notes.findIndex(
      (note) => note.note_for === currentNoteFor
    );
    const nextNoteFor = notes[currentIndex + 1];
    return nextNoteFor ? `${nextNoteFor.path}` : "terms";
  };

  const findNote = (notes, noteFor) =>
    notes.find((note) => note.note_for === noteFor);

  const getStepNumber = (notes, noteFor) =>
    findNote(notes, noteFor)?.step_number;

  return [
    {
      path: "personal-info",
      stage: getStepNumber(notes, "Personal Info"),
      component: PersonalInfoSection,
      props: {
        note: findNote(notes, "Personal Info"),
        path: `/${getNextStepPath(notes, "Personal Info")}`,
      },
    },
    {
      path: "gender",
      stage: getStepNumber(notes, "Gender"),
      component: GenderSection,
      props: {
        genders,
        note: findNote(notes, "Gender"),
        path: `/${getNextStepPath(notes, "Gender")}`,
      },
    },
    {
      path: "weight",
      stage: getStepNumber(notes, "Weight"),
      component: WeightSection,
      props: {
        note: findNote(notes, "Weight"),
        path: `/${getNextStepPath(notes, "Weight")}`,
      },
    },
    {
      path: "carbohydrate-intake",
      stage: getStepNumber(notes, "Carbohydrate Intake"),
      component: CarbohydrateIntake,
      props: {
        carbohydrateIntakes: { carbohydrateIntake },
        note: findNote(notes, "Carbohydrate Intake"),
        path: `/${getNextStepPath(notes, "Carbohydrate Intake")}`,
      },
    },

    {
      path: "routine",
      stage: getStepNumber(notes, "Routine"),
      component: Routine,
      props: {
        note: findNote(notes, "Routine"),
        path: `/${getNextStepPath(notes, "Routine")}`,
      },
    },
    {
      path: "stress",
      stage: getStepNumber(notes, "Stress"),
      component: Stress,
      props: {
        stressLevels,
        note: findNote(notes, "Stress"),
        path: `/${getNextStepPath(notes, "Stress")}`,
      },
    },
    {
      path: "work",
      stage: getStepNumber(notes, "Work"),
      component: Work,
      props: {
        physicallyActive,
        note: findNote(notes, "Work"),
        path: `/${getNextStepPath(notes, "Work")}`,
      },
    },
    {
      path: "Instruction",
      stage: getStepNumber(notes, "Instruction"),
      component: Instruction,
      props: {
        glp: { GLP },
        note: findNote(notes, "Instruction"),
        path: `/${getNextStepPath(notes, "Instruction")}`,
      },
    },

    {
      path: "motivation",
      stage: getStepNumber(notes, "Motivation"),
      component: MotivationField,
      props: {
        glp: { GLP },
        note: findNote(notes, "Motivation"),
        path: `/${getNextStepPath(notes, "Motivation")}`,
      },
    },
    {
      path: "information",
      stage: getStepNumber(notes, "Information"),
      component: Information,
      props: {
        note: findNote(notes, "Information"),
        path: `/${getNextStepPath(notes, "Information")}`,
      },
    },
  ];
};

export default GenerateSections;
