interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  labelsData: labelData[];
}

export default function renderLabels({ labelsData, svg }: Props) {
  svg
    .selectAll(".axislabel")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("g")
        .attr(
          "transform",
          (d, _) =>
            `translate(${
              window.innerWidth <= 500 ? d.number_coord.x : d.label_coord.x
            }, ${
              window.innerWidth <= 500 ? d.number_coord.y : d.label_coord.y
            }), rotate(${window.innerWidth <= 500 ? 0 : d.rotateDegree})`
        )
        .append("text")
        .text((d) => (window.innerWidth <= 500 ? d.number : d.name))
        .attr("class", "label")
    );

  svg
    .selectAll(".circles")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("circle")
        .attr(
          "transform",
          (d, i) => `translate(${d.circle_coord.x}, ${d.circle_coord.y})`
        )
        .attr("r", 3)
        .attr("stroke", (d, i) => d.circle_coord.color)
        .attr("fill", (d, i) => d.circle_coord.color)
        .attr("stroke-width", 2)
    );
}
