import React from "react";
import BmiForm from "../components/BmiForm";
import Logo from "../components/Logo";

function Bmi({ notes, currentStage, setCurrentStage }) {
  return (
    <main>
      <section className="page">
        <BmiForm
          notes={notes}
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
        />

        <Logo title="vragen" />
      </section>
    </main>
  );
}

export default Bmi;
