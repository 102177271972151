interface Props {
  points_for_medication: number;
  points_for_sleep: number;
  points_for_carbs: number;
  points_for_exercise: number;
  points_for_steps: number;
  svgHeight: number;
  startWeight: number;
  targetWeight: number;
  weightLoss: number;
  sleep: number;
  lowCarbIntake: number;
  carbohydrate: number;
  sportFrequency: number;
  exercise: number;
  stepsPerDay: number;
}

const getRandomInt = (min, max): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const calculateTotalPoints = (
  points_for_medication: number,
  points_for_sleep: number,
  points_for_carbs: number,
  points_for_exercise: number,
  points_for_steps: number,
  weightLoss: number,
  sleep: number,
  lowCarbIntake: number,
  carbohydrate: number,
  sportFrequency: number,
  exercise: number,
  stepsPerDay: number
) => {
  const sleepPoints =
    Number(weightLoss) !== Number(sleep) && Number(weightLoss) > Number(sleep)
      ? (Number(weightLoss) - Number(sleep)) * points_for_sleep
      : points_for_sleep;

  const carbsPoints =
    Number(lowCarbIntake) !== Number(carbohydrate) &&
    Number(lowCarbIntake) > Number(carbohydrate)
      ? (Number(lowCarbIntake) - Number(carbohydrate)) * points_for_carbs
      : points_for_carbs;

  const exercisePoints =
    Number(sportFrequency) !== Number(exercise) &&
    Number(sportFrequency) > Number(exercise)
      ? (Number(sportFrequency) - Number(exercise)) * points_for_exercise
      : points_for_exercise;

  const stepsPoints = (Number(stepsPerDay) / 1000) * points_for_steps;

  return (
    sleepPoints +
    carbsPoints +
    exercisePoints +
    points_for_medication +
    stepsPoints
  );
};

const calculateWeightLoss = (
  initialDurationInMonths: number,
  targetDurationInMonths: number,
  startWeight: number,
  targetWeight: number
): number => {
  const weight = startWeight - targetWeight;
  const weightLossPerMonth = weight / initialDurationInMonths;
  const weightLossInTargetDuration =
    weightLossPerMonth * targetDurationInMonths;
  return Math.trunc(weightLossInTargetDuration);
};

const calculateTotalWeightLossTime = (
  points_for_medication: number,
  points_for_sleep: number,
  points_for_carbs: number,
  points_for_exercise: number,
  points_for_steps: number,
  medication: "withMedication" | "withoutMedication",
  startWeight: number,
  targetWeight: number,
  weightLoss: number,
  sleep: number,
  lowCarbIntake: number,
  carbohydrate: number,
  sportFrequency: number,
  exercise: number,
  stepsPerDay: number
) => {
  const totalPoints = calculateTotalPoints(
    medication === "withMedication" ? points_for_medication : 0,
    points_for_sleep,
    points_for_carbs,
    points_for_exercise,
    points_for_steps,
    weightLoss,
    sleep,
    lowCarbIntake,
    carbohydrate,
    sportFrequency,
    exercise,
    stepsPerDay
  );

  const calculatedWeeks = Math.abs(
    Math.log(startWeight / targetWeight) / Math.log(1 - totalPoints / 100)
  );

  const totalMonths = calculatedWeeks / 4.345;

  return totalMonths;
};
const generateLineDataBasedOnWeightLossDuration = (
  totalMonths: number,
  startWeight: number,
  targetWeight: number,
  svgHeight: number,
  isMedication: boolean = false
): Array<number> => {
  var data: Array<number> = [];

  const totalWeightToLose = startWeight - targetWeight;

  const weightLossInTargetDuration = calculateWeightLoss(
    totalMonths,
    6,
    startWeight,
    targetWeight
  );

  const totalPercentOfWeightLoss =
    (weightLossInTargetDuration / totalWeightToLose) * 100;

  const lineEndCoordinate =
    (totalPercentOfWeightLoss / 100) * svgHeight >= svgHeight
      ? svgHeight - 4
      : (totalPercentOfWeightLoss / 100) * svgHeight;

  // Determine the month when the target is achieved
  const targetAchievedMonth = Math.ceil(totalMonths);

  for (let index = 1; index <= 6; index++) {
    if (isMedication && index > targetAchievedMonth) {
      // Do not push more data if target is achieved for the line with medication
      break;
    }
    if (index === 1) {
      data.push(getRandomInt(5, 10));
    } else {
      data.push(
        getRandomInt(
          (lineEndCoordinate / 6) * index - 5,
          (lineEndCoordinate / 6) * index + 5
        )
      );
    }
  }

  return data;
};

export default function generateLineData({
  points_for_carbs,
  points_for_exercise,
  points_for_medication,
  points_for_sleep,
  points_for_steps,
  startWeight,
  svgHeight,
  targetWeight,
  carbohydrate,
  exercise,
  lowCarbIntake,
  sleep,
  stepsPerDay,
  sportFrequency,
  weightLoss,
}: Props): [Array<number>, Array<number>] {
  const totalMonthsWithoutMedication = Math.trunc(
    calculateTotalWeightLossTime(
      points_for_medication,
      points_for_sleep,
      points_for_carbs,
      points_for_exercise,
      points_for_steps,
      "withoutMedication",
      startWeight,
      targetWeight,
      weightLoss,
      sleep,
      lowCarbIntake,
      carbohydrate,
      sportFrequency,
      exercise,
      stepsPerDay
    )
  );

  const totalMonthsWithMedication = Math.trunc(
    calculateTotalWeightLossTime(
      points_for_medication,
      points_for_sleep,
      points_for_carbs,
      points_for_exercise,
      points_for_steps,
      "withMedication",
      startWeight,
      targetWeight,
      weightLoss,
      sleep,
      lowCarbIntake,
      carbohydrate,
      sportFrequency,
      exercise,
      stepsPerDay
    )
  );

  var withoutMedication: Array<number> =
    generateLineDataBasedOnWeightLossDuration(
      totalMonthsWithoutMedication,
      startWeight,
      targetWeight,
      svgHeight
    );

  var withMedication: Array<number> = generateLineDataBasedOnWeightLossDuration(
    totalMonthsWithMedication,
    startWeight,
    targetWeight,
    svgHeight
  );

  const endMonthWithMedication = Math.ceil(totalMonthsWithMedication);

  // If the end month with medication is less than 6,
  // then limit the data array to that length
  if (endMonthWithMedication < 6) {
    // Set the last point of the line with medication to reach the bottom.
    withMedication[endMonthWithMedication - 1] = svgHeight;
    withMedication = withMedication.slice(0, endMonthWithMedication);
  }

  return [withoutMedication, withMedication];
}
