import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputRange from "react-input-range";
import { useNavigate } from "react-router-dom";
import { usePatientContext } from "../context/PatientContext";
import ProgressBar from "./layouts/ProgressBar";

function WeightReductionForm({ notes, currentStage, setCurrentStage }) {
  const {
    desiredHoursOfSleep,
    setDesiredHoursOfSleep,
    desiredCarbohydrateIntake,
    setDesiredCarbohydrateIntake,
    sportFrequency,
    setSportFrequency,
    stepsPerDay,
    setStepsPerDay,
    firstName,
    lastName,
  } = usePatientContext();

  const navigate = useNavigate();
  const [route, setRoute] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    sessionStorage.setItem("desiredHourOfSleep", desiredHoursOfSleep);
    sessionStorage.setItem(
      "desiredCarbohydrateIntake",
      desiredCarbohydrateIntake
    );
    sessionStorage.setItem("sportFrequency", sportFrequency);
    sessionStorage.setItem("stepsPerDay", stepsPerDay);
    navigate(`/${route}`);
  };

  const getNextStepPath = (notes, currentNoteFor) => {
    const currentIndex = notes.findIndex(
      (note) => note.note_for === currentNoteFor
    );
    const nextNoteFor = notes[currentIndex + 1];
    return nextNoteFor ? `${nextNoteFor.path}` : "terms";
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  });

  useEffect(() => {
    setRoute(getNextStepPath(notes, "Weight Plan"));
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bmi-form-container">
      <form
        className="bmi-form padding"
        style={{ gap: "2rem" }}
        onSubmit={handleSubmit}
      >
        {/* <p
          style={{
            width: "100%",
            textAlign: "right",
            fontWeight: "bold",
            paddingRight: "20px",
            fontSize: "0.9rem",
            paddingBottom: "15px",
          }}
        >
          {firstName} {lastName}
        </p> */}

        <ProgressBar
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
          notes={notes}
        />

        <h2 style={{ marginTop: "1rem" }} className="heading">
          {t("weight-plan-heading")}
        </h2>
        <div className="input-wrapper-vertical">
          <label htmlFor="weight-loss" className="input-label">
            {t("desired-sleep-label")}:
          </label>

          <div
            style={{
              marginBottom: ".5rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <InputRange
              maxValue={10}
              minValue={1}
              value={parseInt(desiredHoursOfSleep)}
              onChange={(newValue) =>
                setDesiredHoursOfSleep(newValue.toString())
              }
            />
            <input
              type="number"
              className="range-number-input"
              min={1}
              max={10}
              required
              value={parseInt(desiredHoursOfSleep)}
              onChange={(event) => {
                const newValue =
                  event.target.value === "" ? "0" : event.target.value;
                setDesiredHoursOfSleep(newValue);
              }}
            />
          </div>
        </div>
        <div className="input-wrapper-vertical">
          <label htmlFor="low-carb-intake" className="input-label">
            {t("desired-carbohydrate-label")}:
          </label>

          <div
            style={{
              marginBottom: ".5rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <InputRange
              maxValue={3}
              minValue={0}
              value={parseInt(desiredCarbohydrateIntake)}
              onChange={(newValue) =>
                setDesiredCarbohydrateIntake(newValue.toString())
              }
            />
            <input
              type="number"
              className="range-number-input"
              min="0"
              value={desiredCarbohydrateIntake}
              onChange={(event) => {
                const newValue =
                  event.target.value === "" ? "0" : event.target.value;
                setDesiredCarbohydrateIntake(newValue);
              }}
              max="3"
              required
            />
          </div>
        </div>
        <div className="input-wrapper-vertical">
          <label htmlFor="sport-frequencies" className="input-label">
            {t("desired-exercise-label")}:
          </label>

          <div
            style={{
              marginBottom: ".5rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <InputRange
              maxValue={7}
              minValue={0}
              value={parseInt(sportFrequency)}
              onChange={(newValue) => setSportFrequency(newValue.toString())}
            />
            <input
              type="number"
              className="range-number-input"
              min="0"
              onChange={(event) => {
                const newValue =
                  event.target.value === "" ? "0" : event.target.value;
                setSportFrequency(newValue);
              }}
              value={sportFrequency}
              max="7"
              required
            />
          </div>
        </div>
        <div className="input-wrapper-vertical">
          <label htmlFor="steps-per-day" className="input-label">
            {t("steps-label")}:
          </label>
          <div
            style={{
              marginBottom: ".5rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <InputRange
              maxValue={15000}
              minValue={1000}
              step={500}
              value={parseInt(stepsPerDay)}
              onChange={(newValue) => setStepsPerDay(newValue.toString())}
            />
            <input
              type="number"
              className="range-number-input"
              min="1000"
              max="15000"
              required
              value={stepsPerDay}
              onChange={(event) => {
                const newValue =
                  event.target.value === "" ? "0" : event.target.value;
                setStepsPerDay(newValue);
              }}
              step={500}
            />
          </div>
        </div>
        <div
          className="input-wrapper"
          style={{ display: windowWidth < 1450 ? "none" : "" }}
        >
          <button
            type="submit"
            className="button-primary button-full"
            style={{ marginTop: 20 }}
          >
            {t("Next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default WeightReductionForm;
