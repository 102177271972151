import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useSpring, animated } from "react-spring";
import Notes from "../layouts/Notes";
import { usePatientContext } from "../../context/PatientContext";
import { revealAnimation } from "./animation";
import Testimonial from "../layouts/Testimonial";

function WeightSection({ note, path }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const animationProps = useSpring(revealAnimation);
  const {
    weight,
    setWeight,
    targetWeight,
    setTargetWeight,
    height,
    setHeight,
  } = usePatientContext();

  const currentLanguage = i18n.language;

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("weight", weight);
    sessionStorage.setItem("targetWeight", targetWeight);
    sessionStorage.setItem("height", height);
    navigate(path);
  };

  return (
    <>
      <animated.div style={animationProps}>
        <form
          onSubmit={handleSubmit}
          style={{ display: "grid", gap: "1.3rem", width: "100%" }}
          className="max-width"
        >
          <div className="input-wrapper">
            <label htmlFor="weight" className="input-label">
              {t("weight-label")}?
            </label>
            <div
              style={{
                marginBottom: ".5rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputRange
                maxValue={180}
                minValue={50}
                formatLabel={(value) => `${value} kg`}
                value={parseInt(weight)}
                onChange={(newValue) => setWeight(newValue.toString())}
              />
              <input
                type="number"
                className="range-number-input"
                min={50}
                max={180}
                step={1}
                value={weight}
                onChange={(event) => setWeight(event.target.value)}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <label htmlFor="target-weight" className="input-label">
              {t("target-weight-label")}?
            </label>
            <div
              style={{
                marginBottom: ".5rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputRange
                maxValue={parseInt(weight) - 1}
                minValue={50}
                formatLabel={(value) => `${value} kg`}
                value={parseInt(targetWeight)}
                onChange={(newValue) => setTargetWeight(newValue.toString())}
              />
              <input
                type="number"
                className="range-number-input"
                min={50}
                max={parseInt(weight) - 1}
                step={1}
                value={targetWeight}
                onChange={(event) => setTargetWeight(event.target.value)}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <label htmlFor="height" className="input-label">
              {t("height-label")}?
            </label>
            <div
              style={{
                marginBottom: ".5rem",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <InputRange
                maxValue={220}
                minValue={140}
                formatLabel={(value) => `${value} cm`}
                value={parseInt(height)}
                onChange={(newValue) => setHeight(newValue.toString())}
              />
              <input
                type="number"
                className="range-number-input"
                min={50}
                max={220}
                step={1}
                value={height}
                onChange={(event) => setHeight(event.target.value)}
              />
            </div>
          </div>
          <button type="submit" className="button-primary button-full">
            {t("Next")}
          </button>
        </form>
        <Testimonial
          name={"step1-testimonial-heading"}
          specialization={"step1-testimonial-description"}
          image={`/images/weight_journey${
            currentLanguage == "en" ? "-en" : ""
          }.png`}
          noDote
          scaleImage
        />
      </animated.div>
    </>
  );
}

export default WeightSection;
