interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  labelsData: {
    name: any;
    angle: number;
    rotateDegree: number;
    line_coord: {
      x: number;
      y: number;
    };
    label_coord: {
      x: number;
      y: number;
    };
    circle_coord: {
      color: string;
      x: number;
      y: number;
    };
  }[];
  svgWidth: number;
  svgHeight: number;
}

export default function renderAxisLines({
  labelsData,
  svg,
  svgHeight,
  svgWidth,
}: Props) {
  svg
    .selectAll("line")
    .data(labelsData)
    .join((enter) =>
      enter
        .append("line")
        .attr("x1", svgWidth / 2)
        .attr("y1", svgHeight / 2)
        .attr("x2", (d) => d.line_coord.x)
        .attr("y2", (d) => d.line_coord.y)
        .attr("stroke", "black")
    );
}
