import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorRing } from "react-loader-spinner";

type Props = {};

function FinalPage({}: Props) {
  const { t } = useTranslation();
  return (
    <main>
      <section className="page">
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 .5rem",
            alignItems: "center",

            textAlign: "center",
          }}
        >
          <div style={{ display: "grid", gap: ".6rem", padding: ".6rem" }}>
            <h3>{t("Thank you for completing the survey")}</h3>
            <p
              style={{ fontWeight: "500" }}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Do you have another question Ask your question in our contact form or call directly on 0858 200 200"
                ),
              }}
            ></p>
          </div>
          <button
            className="button-primary"
            onClick={() => {
              localStorage.clear();
              window.location.href = "https://helloweight.nl";
            }}
          >
            {t("Go to HelloWeight.nl")}
          </button>
        </div>
      </section>
    </main>
  );
}

export default FinalPage;
