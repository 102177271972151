interface PathsDataProps {
  motivation: number;
  sleep: number;
  exercise: number;
  stressLevel: number;
  carbohydrates: number;
  workType: number;
  dateOfBirth: string;
  heightCM: number;
  targetWeight: number;
  currentWeight: number;
}

// This function generates random values to render the paths inside the chart
// USE this only for testing or in developing environment
const generateRandomPathData = (features) => {
  var randomGeneratedData = [];

  //generate the data
  for (var i = 0; i < 1; i++) {
    var point = {};
    //each feature will be a random number from 1-9
    features.forEach((f) => (point[f] = 1 + Math.random() * 9));
    randomGeneratedData.push(point);
  }
};

const getStressLevel = (stressLevel: number): Number => {
  if (stressLevel == 0.5) return 10;
  else if (stressLevel == 1) return 8;
  else if (stressLevel == 2) return 6;
  else return 5;
};

const getMovement = (): Number => {
  return 6;
};

const getExercisePerWeek = (exercise: number): Number => {
  if (exercise <= 1) return 2;
  else if (exercise <= 5) return 4;
  else if (exercise > 5 || exercise < 10) return 8;
  else if (exercise >= 10) return 10;
  else return exercise;
};

const getMotivation = (): Number => 8;

const getLengthInCm = (heightCM: number): Number => {
  if (heightCM < 120 && heightCM > 100) return 5;
  else if (heightCM >= 120) return 7;
  else if (heightCM >= 150) return 8;
  else if (heightCM >= 170) return 9;
  else if (heightCM >= 180) return 10;
  else return 3;
};

const getHourOfSleep = (sleep: number): Number => {
  if (sleep <= 1) return 2;
  else if (sleep >= 10) return 10;
  else return sleep;
};

const getIntakeCarbohydrates = (carbohydrates: number): Number => {
  if (carbohydrates == 0.5) return 10;
  else if (carbohydrates == 1) return 8;
  else if (carbohydrates == 2) return 6;
  else return 5;
};

const getTypeOfWork = (workType: number): Number => {
  if (workType == 0.5) return 10;
  else if (workType == 1) return 8;
  else if (workType == 2) return 6;
  else return 5;
};

const getWeightInKg = (currentWeight: number, targetWeight: number): Number => {
  const overweight = currentWeight - targetWeight;

  if (overweight >= 5) return 6;
  else if (overweight >= 10) return 6.5;
  else if (overweight >= 15) return 7;
  else if (overweight >= 20) return 7.5;
  else if (overweight >= 25) return 8.5;
  else if (overweight >= 30) return 8;
  else if (overweight >= 35) return 9.5;
  else if (overweight >= 40) return 10;
  else return 2;
};

const getAge = (dateOfBirth: string): Number => {
  const date = new Date(dateOfBirth).getFullYear();
  const currentDate = new Date().getFullYear();
  const age = currentDate - date;

  if (age >= 5) return 6;
  else if (age >= 10) return 6.5;
  else if (age >= 15) return 7;
  else if (age >= 20) return 7.5;
  else if (age >= 25) return 8.5;
  else if (age >= 30) return 8;
  else if (age >= 35) return 9.5;
  else if (age >= 40) return 10;
  else return 2;
};

export default function generatePathData({
  carbohydrates,
  dateOfBirth,
  exercise,
  sleep,
  stressLevel,
  workType,
  heightCM,
  currentWeight,
  targetWeight,
}: PathsDataProps) {
  var data: Array<Object> = [
    {
      "1": getMovement(), // Movement
      "2": getExercisePerWeek(exercise), // Sports per week
      "3": getMotivation(), // Motivation
      "4": getLengthInCm(heightCM), // Length in Cm
      "5": getHourOfSleep(sleep), // Hours of sleep per night
      "6": getIntakeCarbohydrates(carbohydrates), // Intake carbohydrates
      "7": getTypeOfWork(workType), // Type of work
      "8": getStressLevel(stressLevel), //Stress level
      "9": getWeightInKg(currentWeight, targetWeight), // Weight in kg
      "10": getAge(dateOfBirth), // Age
    },
  ];

  return data;
}
