type labelDataType = {
  name: string;
  label_coord_x: number;
  line_coord_x: number;
};

export default function generateMonthsLabelsData(
  svgWidth: number,
  t: any
): Array<labelDataType> {
  var labelsData: Array<labelDataType> = [];

  const getMonthNameAndCurrentDate = (
    index: number
  ): { monthName: string; currentDate: number } => {
    const currentDate = new Date();

    if (currentDate.getDate() >= 30) {
      currentDate.setDate(29);
      currentDate.setMonth(currentDate.getMonth() + index + 1);
    } else {
      currentDate.setMonth(currentDate.getMonth() + index);
    }

    return {
      monthName: t(currentDate.toLocaleString("default", { month: "long" })),
      currentDate: currentDate.getDate(),
    };
  };

  const getLineCoordToMonthDate = (index: number) => {
    const remainingDays = 29 - getMonthNameAndCurrentDate(index).currentDate;

    if (index === 0) {
      return -10;
    }
    if (index === 1) {
      let initialPercent = 17;
      if (remainingDays <= 5) {
        return ((initialPercent - 11.5) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 10) / 100) * svgWidth;
      }
      if (remainingDays <= 15) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 2) {
      let initialPercent = 37;
      if (remainingDays <= 5) {
        return ((initialPercent - 12) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 10) / 100) * svgWidth;
      }
      if (remainingDays <= 15) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 3) {
      let initialPercent = 57;
      if (remainingDays <= 5) {
        return ((initialPercent - 12) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 10) / 100) * svgWidth;
      }
      if (remainingDays <= 15) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 4) {
      let initialPercent = 77;
      if (remainingDays <= 5) {
        return ((initialPercent - 12) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 10) / 100) * svgWidth;
      }
      if (remainingDays <= 15) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return ((initialPercent - 2) / 100) * svgWidth;
      }
    }
    if (index === 5) {
      let initialPercent = 97;
      if (remainingDays >= 25) {
        return ((initialPercent - 5) / 100) * svgWidth;
      }
      if (remainingDays <= 5) {
        return ((initialPercent - 12) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 10) / 100) * svgWidth;
      }
      if (remainingDays <= 15) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return ((initialPercent - 12) / 100) * svgWidth;
      }
    }
  };

  const getLabelCoordToMonthDate = (index: number) => {
    const remainingDays = 30 - getMonthNameAndCurrentDate(index).currentDate;

    if (index === 0) return -25;
    if (index === 1) {
      let initialPercent = 17;
      if (remainingDays <= 5) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 6) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 2) {
      let initialPercent = 37;

      if (remainingDays <= 5) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 6) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 3) {
      let initialPercent = 56;

      if (remainingDays <= 5) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 6) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 4) {
      let initialPercent = 74;

      if (remainingDays <= 5) {
        return ((initialPercent - 7) / 100) * svgWidth;
      }
      if (remainingDays <= 10) {
        return ((initialPercent - 6) / 100) * svgWidth;
      }
      if (remainingDays <= 20) {
        return ((initialPercent - 5) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
    if (index === 5) {
      let initialPercent = 91;
      if (remainingDays <= 10) {
        return ((initialPercent - 2) / 100) * svgWidth;
      } else {
        return (initialPercent / 100) * svgWidth;
      }
    }
  };

  for (let index = 0; index < 6; index++) {
    labelsData.push({
      name: getMonthNameAndCurrentDate(index).monthName,
      label_coord_x: getLabelCoordToMonthDate(index),
      line_coord_x: getLineCoordToMonthDate(index),
    });
  }

  return labelsData;
}
