type labelDataType = {
  name: string;
  label_coord_x: number;
  line_coord_x: number;
};

interface Props {
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  data: Array<labelDataType>;
  svgHeight: number;
  t: any;
  svgWidth: number;
}

export default function renderMonthsLabels({
  data,
  svg,
  svgHeight,
  svgWidth,
  t,
}: Props) {
  const modifyLabelCoordinates = (name: string, x_coordinate) => {
    if (name.length >= 1 && name.length <= 3) {
      return `translate(${
        window.innerHeight < 500 ? x_coordinate - 20 : x_coordinate - 5
      }, ${svgHeight + 25})`;
    } else if (name.length > 3 && name.length <= 5) {
      return `translate(${x_coordinate - 10}, ${svgHeight + 25})`;
    } else if (name.length > 5 && name.length <= 7) {
      return `translate(${x_coordinate - 15}, ${svgHeight + 25})`;
    } else {
      return `translate(${x_coordinate - 22}, ${svgHeight + 25})`;
    }
  };

  svg
    .selectAll("texts")
    .data(data)
    .join((enter) =>
      enter
        .append("g")
        .attr("transform", (d, i) =>
          modifyLabelCoordinates(
            window.innerWidth < 500 ? d.name.slice(0, 3) : d.name,
            d.label_coord_x
          )
        )
        .append("text")
        .text((d) => (window.innerWidth <= 700 ? d.name.slice(0, 3) : d.name))
        .style("font-size", window.innerWidth > 500 ? "15" : "1.3rem")
        .style("color", "#111111")
    );

  svg
    .append("g")
    .attr(
      "transform",
      (d, i) =>
        `translate(${window.innerWidth > 500 ? -80 : -70}, ${svgHeight + 45})`
    )
    .append("text")
    .text(t("Month"))
    .style("font-size", "16")
    .style("color", "#111111");

  svg
    .selectAll("line")
    .data(data)
    .join("line")
    .attr("x1", (d, i) => d.line_coord_x + 5)
    .attr("y1", (d) => svgHeight)
    .attr("x2", (d, i) => d.line_coord_x + 5)
    .attr("y2", (d) => 0)
    .attr("stroke", "#000000")
    .style("display", (d) => (d.label_coord_x < 1 ? "none" : ""));

  svg
    .selectAll("verticalLine")
    .data([0])
    .join((enter) =>
      enter
        .append("line")
        .attr("x1", 0)
        .attr("y1", svgHeight - 30)
        .attr("x2", svgWidth)
        .attr("y2", svgHeight - 25)
        .attr("stroke", "#000000")
        .style("stroke-dasharray", "3, 3")
    );
}
